import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {MatRipple} from '@angular/material/core';
import {MatIcon} from '@angular/material/icon';

@Component({
    selector: 'nn-favorites-header',
    templateUrl: 'component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        RouterLink,
        MatRipple,
        RouterLinkActive,
        MatIcon,
    ],
})
export class FavoritesHeaderComponent {
}
