export enum MenuIcons {
    Signin = '/assets/img/menu/signin.svg',
    SigninActive = '/assets/img/menu/signin_active.svg',
    Join = '/assets/img/menu/join.svg',
    JoinActive = '/assets/img/menu/join_active.svg',
    Search = '/assets/img/menu/search.svg',
    SearchActive = '/assets/img/menu/search_active.svg',
    Providers = '/assets/img/menu/providers.svg',
    ProvidersActive = '/assets/img/menu/providers_active.svg',
    Jobs = '/assets/img/menu/jobs.svg',
    JobsActive = '/assets/img/menu/jobs_active.svg',
    Profiles = '/assets/img/menu/profiles.svg',
    ProfilesActive = '/assets/img/menu/profiles_active.svg',
    Favorites = '/assets/img/menu/favorites.svg',
    FavoritesActive = '/assets/img/menu/favorites_active.svg',
    Chat = '/assets/img/menu/chat.svg',
    ChatActive = '/assets/img/menu/chat_active.svg',
}
