<nav class="menu menu_account">
    @for (item of items; track item) {
        <div class="menu__item">
            @switch (item) {
                @case (Menu.ResumeSearch) {
                    <nn-resume-search-menu-item [loggedIn]="!!user"
                        [isActive]="state| nnActiveMobileMenu:Menu.ResumeSearch"/>
                }
                @case (Menu.VacancySearch) {
                    <nn-vacancy-search-menu-item [loggedIn]="!!user"
                        [isActive]="state | nnActiveMobileMenu:Menu.VacancySearch"/>
                }
                @case (Menu.ResumeManagement) {
                    <nn-regular-menu-item [isActive]="state | nnActiveMobileMenu:Menu.ResumeManagement"
                        [title]="'mobile_nav|resume_management' | localization"
                        [highlightedIcon]="MenuIcons.ProfilesActive"
                        [icon]="MenuIcons.Profiles"
                        link="/resume-management"
                        item="ResumeManagement"/>
                }
                @case (Menu.Favorites) {
                    <nn-regular-menu-item [isActive]="state | nnActiveMobileMenu:Menu.Favorites"
                        [title]="'mobile_nav|favorites' | localization"
                        [highlightedIcon]="MenuIcons.FavoritesActive"
                        [icon]="MenuIcons.Favorites"
                        link="/favorites"
                        item="Favorites"/>
                }
                @case (Menu.Chat) {
                    <nn-regular-menu-item [isActive]="state | nnActiveMobileMenu:Menu.Chat"
                        [title]="'mobile_nav|chat' | localization"
                        [highlightedIcon]="MenuIcons.ChatActive"
                        [icon]="MenuIcons.Chat"
                        link="/chat"
                        item="Chat">
                        @if (0 | nnTotalUnreadChatMessagesCount | async; as count) {
                            <span class="menu__count">
                                {{ count }}
                            </span>
                        }
                    </nn-regular-menu-item>
                }
                @case (Menu.Login) {
                    <nn-regular-menu-item [isActive]="state | nnActiveMobileMenu:Menu.Login"
                        [title]="'mobile_nav|login' | localization"
                        [highlightedIcon]="MenuIcons.SigninActive"
                        [icon]="MenuIcons.Signin"
                        link="/login"
                        item="Login"/>
                }
                @case (Menu.Register) {
                    <nn-regular-menu-item [isActive]="state | nnActiveMobileMenu:Menu.Register"
                        [title]="'mobile_nav|register' | localization"
                        [highlightedIcon]="MenuIcons.JoinActive"
                        [icon]="MenuIcons.Join"
                        link="/register"
                        item="Register"/>
                }
                @case (Menu.Account) {
                    <nn-profile-menu-item [user]="user"
                        [isActive]="state | nnActiveMobileMenu:Menu.Account"/>
                }
            }
        </div>
    }
</nav>
