import {BehaviorSubject, defer, Observable, share} from 'rxjs';
import {distinctUntilChanged, finalize, switchMap, take} from 'rxjs/operators';
import {ServerSessionService} from './server-session.service';
import {User} from '../../../../infrastructure/auth/models/user';

export class ServerSessionRequestOptimizerDecorator extends ServerSessionService {
    private isForcedSessionRefreshRequested = new BehaviorSubject(false);
    private readonly obs$: Observable<User>;

    constructor(serverSessionService: ServerSessionService) {
        super();
        this.obs$ = this.isForcedSessionRefreshRequested.pipe(
            distinctUntilChanged((x, y) => x === y || !y),
            switchMap((force) => serverSessionService.getServerState(force)),
            take(1),
            finalize(() => this.isForcedSessionRefreshRequested.next(false)),
            share(),
        );
    }

    public override getServerState(forceRefresh: boolean): Observable<User> {
        return defer(() => {
            if (forceRefresh)
                this.isForcedSessionRefreshRequested.next(true);
            return this.obs$;
        });
    }
}
