import {makeEnvironmentProviders} from '@angular/core';
import {ServiceWorkerCheckForUpdatesService} from './service-worker-check-for-updates.service';
import {ServiceWorkerForceUpdateService} from './service-worker-force-update.service';

export function provideServiceWorkerFeatures() {
    return makeEnvironmentProviders([
        {provide: ServiceWorkerCheckForUpdatesService},
        {provide: ServiceWorkerForceUpdateService},
    ]);
}
