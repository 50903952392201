import {APP_INITIALIZER, makeEnvironmentProviders} from '@angular/core';
import {MapStateService} from './services/map-state.service';

function initMapStateServiceFactory(mapStateService: MapStateService): () => void {
    return () => mapStateService.init();
}

export function provideMapStateService() {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: initMapStateServiceFactory,
            deps: [MapStateService],
            multi: true,
        },
    ]);
}
