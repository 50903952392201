import {inject, InjectionToken} from '@angular/core';
import {JwtOptions} from '../../../app/infrastructure/auth/jwtOptions';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../../../app/infrastructure/app-config/AppConfig';
import {TokenService} from '../../../app/core/users/core/session/token.service';

function jwtOptionsFactory(): JwtOptions {
    return {
        tokenGetter: (): Observable<string> => {
            const tokenService = inject(TokenService);
            return tokenService.getAccessToken();
        },
        whitelistedDomains: inject(APP_CONFIG).securedHosts,
    };
}

export const JWT_OPTIONS = new InjectionToken<JwtOptions>('JWT_OPTIONS', {
    providedIn: 'root',
    factory: jwtOptionsFactory,
});
