@if (identityService.stateChanges | async; as authState) {

    @if (catalogMenuService.currentCatalog$ | async; as currentCatalog) {

        <header class="header"
            [ngClass]="{'header_filter': currentCatalog.catalogType !== catalogType.Undefined && currentCatalog.action === 'search'}">
            <div class="header__main">
                <div class="header__container">
                    <div class="header__center center">
                        <a class="header__logo header__logo-RU"
                            *nnCountryDomain="'RU'"
                            routerLink="/"></a>
                        <a class="header__logo header__logo-CA"
                            *nnCountryDomain="'CA'"
                            routerLink="/"></a>
                        <a class="header__logo header__logo-IL"
                            *nnCountryDomain="'IL'"
                            routerLink="/"></a>
                        <a class="header__logo header__logo-UK"
                            *nnCountryDomain="'UK'"
                            routerLink="/"></a>
                        <a class="header__logo header__logo-US"
                            *nnCountryDomain="'US'"
                            routerLink="/"></a>
                        <div class="header__menu">
                            <button class="header__menu-link"
                                (click)="openCatalogMenu(catalogType.Resume)"
                                [ngClass]="{'header__menu-link_active': catalogType.Resume === currentCatalog.catalogType, 'header__menu-link_opened': menuService.catalog === CatalogType.Resume}"
                                matRipple
                                type="button">
                                <span class="header__menu-full">
                                    {{ 'home_page|header_find_worker' | localization }}
                                </span>
                                <span class="header__menu-short">
                                    {{ 'home_page|header_find_worker_short' | localization }}
                                </span>
                            </button>
                            <button class="header__menu-link"
                                (click)="openCatalogMenu(catalogType.Vacancy)"
                                [ngClass]="{ 'header__menu-link_active': catalogType.Vacancy === currentCatalog.catalogType, 'header__menu-link_opened': menuService.catalog === CatalogType.Vacancy}"
                                matRipple
                                type="button">
                                <span class="header__menu-full">
                                    {{ 'home_page|header_find_work' | localization }}
                                </span>
                                <span class="header__menu-short">
                                    {{ 'home_page|header_find_work_short' | localization }}
                                </span>
                            </button>
                            @if (!authState.user || authState.user.isWorker) {
                                <button class="header__menu-link"

                                    (click)="openInterestingMenu()"
                                    [ngClass]="{ 'header__menu-link_opened': menuService.interesting }"
                                    matRipple
                                    type="button">
                                <span class="header__menu-text">
                                    {{ 'home_page|header_interesting' | localization }}
                                </span>
                                </button>
                            }
                            @if (currentCatalog.catalogType !== catalogType.Undefined && currentCatalog.action === 'search') {
                                <button class="header__menu-link header__menu-link_filters mat-mdc-button_border"

                                    (click)="showFilterDialog()"
                                    color="accent"
                                    mat-button
                                    type="button">
                                    <mat-icon class="mat-icon_search header__menu-icon"
                                        svgIcon="sprite:icon-search"/>
                                    <span class="header__menu-text">
                                    {{ 'home_page|header_filters' | localization }}
                                </span>
                                </button>
                            }
                        </div>
                        <div class="header__service">
                            <nn-header-auth-state class="header__user"/>
                            @if (!authState.user) {
                                <button class="header__register mat-elevation-z8"

                                    routerLink="/register"
                                    color="accent"
                                    mat-raised-button>
                                    {{ 'home_page|signup' | localization }}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </header>
        @defer {
            <nn-header-desktop-menu/>
        }

    }

}
