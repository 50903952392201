import {CanActivateFn, createUrlTreeFromSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {IdentityService} from '../../../core/users/core/identity.service';
import {map} from 'rxjs/operators';

export function agencyGuard(negate: boolean = false): CanActivateFn {
    return route => {
        return inject(IdentityService).stateChanges.pipe(
            map(authState => {
                const user = authState.user;
                if (!user) return createUrlTreeFromSnapshot(route, ['/login']);

                let isAgency = user.isEmployer && user.isAgency;
                if (negate) isAgency = !isAgency;

                return isAgency || createUrlTreeFromSnapshot(route, ['/error']);
            }),
        );
    };
}
