@if (viewMode === 'full') {
    <a class="notifications-header mat-elevation-z8"

        (click)="showNotificationsPanel()"
        [ngClass]="{'notifications-header_active': dialogOpened}">
        <div class="notifications-header__circle"
            mat-ripple>
            <mat-icon class="notifications-header__icon mat-icon_bell"
                svgIcon="sprite:icon-bell"/>
        </div>
        @if (unreadCount$ | async; as count) {
            <span class="notifications-header__count">{{ count }}</span>
        }
    </a>
}
@if (viewMode === 'short') {
    <button class="notification"

        (click)="showNotificationsPanel()"
        type="button">
        <img class="notification__img"
            width="40px"
            height="44px"
            src="/assets/img/menu/bell.svg"
            alt="Bell">
        @if (unreadCount$ | async; as count) {
            <span class="notification__count">
        {{ count }}
    </span>
        }
    </button>
}
