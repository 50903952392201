import {APP_INITIALIZER, makeEnvironmentProviders} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../app-config/AppConfig';
import {WINDOW_TOKEN} from '../dom/WINDOW_TOKEN';
import {TurnstileService} from './turnstile.service';

function initTurnstile(appConfig: AppConfig, window: Window): () => void {
    return () => {
        const config = appConfig.turnstileConfig;
        if (config.managed.enabled || config.invisible.enabled)
            TurnstileService.init(window);
    };
}

export function provideTurnstile() {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: initTurnstile,
            deps: [
                APP_CONFIG,
                WINDOW_TOKEN,
            ],
            multi: true,
        },
    ]);
}
