import {Component} from '@angular/core';
import {AlertsService} from '../../infrastructure/alerts/alerts.service';
import {AsyncPipe} from '@angular/common';
import {LocalizationPipe} from '../../infrastructure/localization/localization.pipe';
import {MatIcon} from '@angular/material/icon';

@Component({
    selector: 'nn-layout-error-message',
    templateUrl: 'error-message.component.html',
    styleUrls: ['error-message.component.sass'],
    standalone: true,
    imports: [
        AsyncPipe,
        LocalizationPipe,
        MatIcon,
    ],
})
export class ErrorMessageComponent {
    constructor(public alertsService: AlertsService) {
    }

    public clearMessage(): void {
        this.alertsService.clearErrors();
    }
}
