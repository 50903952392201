import {inject, Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {TimeTrackingStateService} from './time-tracking-state.service';
import {TIME_TRACKING_NAME} from './tokens/time-tracking-name.token';
import {AppTimersService} from '../../../../../infrastructure/timers/app-timers.service';

@Injectable()
export class TimeTrackingService implements OnDestroy {
    private readonly interval = 1000 * 5;
    private readonly elapsedTimeSubject$ = new Subject<number>();
    private readonly stopSubject$ = new Subject<void>();
    private readonly appTimersService = inject(AppTimersService);

    constructor(
        private readonly stateService: TimeTrackingStateService,
        @Inject(TIME_TRACKING_NAME) private readonly name: string) {
    }

    public get elapsedMs$(): Observable<number> {
        return this.elapsedTimeSubject$.asObservable();
    }

    public startTimeTracking(userId: string): void {
        this.start(userId, false);
    }

    public restartTimeTracking(userId: string): void {
        this.start(userId, true);
    }

    public stopTimeTracking(): void {
        this.stopSubject$.next();
    }

    public ngOnDestroy(): void {
        this.stopTimeTracking();
    }

    private start(userId: string, reset: boolean): void {

        if (reset) {
            this.stateService.reset(userId, this.name);
        }
        this.appTimersService.getInterval({period: this.interval}).pipe(
            map(() => this.stateService.increase(userId, this.name, this.interval)),
            tap(elapsed => this.elapsedTimeSubject$.next(elapsed)),
            takeUntil(this.stopSubject$),
        ).subscribe();
    }
}
