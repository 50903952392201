import {UnreadMessagesFormatter} from './unread-messages-formatter';

export class RuUnreadMessagesFormatter implements UnreadMessagesFormatter {
    public format(messageCount: number): string {
        const mod10 = messageCount % 10;
        const mod100 = messageCount % 100;
        if (mod100 > 5 && mod100 < 21) {
            return `${messageCount} непрочитанных сообщений`;
        } else {
            if (mod10 === 1) {
                return `${messageCount} непрочитанное сообщение`;
            } else if (mod10 > 1 && mod10 < 5) {
                return `${messageCount} непрочитанных сообщения`;
            } else {
                return `${messageCount} непрочитанных сообщений`;
            }
        }
    }
}
