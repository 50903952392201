import {inject, Injectable} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {APP_CONFIG} from '../app-config/AppConfig';
import {PageMetaService} from './page-meta.service';
import {PageMetaConfigMap} from './models/config/page-meta.config';
import {mergeConfigs} from './misc/merge-configs.func';

@Injectable({
    providedIn: 'root',
})
export class PageMetaMonitor {
    private readonly metaService = inject(PageMetaService);
    private readonly router = inject(Router);
    private readonly countryDomain = inject(APP_CONFIG).countryDomain;

    public run(): void {
        const routeSnapshot$ = this.router.events.pipe(
            filter(event => event instanceof ActivationEnd),
            map((event: ActivationEnd) => event.snapshot),
        );

        const configMap$ = routeSnapshot$.pipe(
            filter(snapshot => !snapshot.firstChild && !snapshot.data?.manualSetPageMetaData),
            map(snapshot => snapshot.data?.pageMetaConfigMap),
        );

        configMap$.subscribe((configMap: PageMetaConfigMap) => {
            const config = mergeConfigs(configMap?.baseConfig, configMap?.[this.countryDomain]);
            this.metaService.setPageMetaData(config.data, config.options);
        });
    }
}
