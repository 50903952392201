import {makeEnvironmentProviders} from '@angular/core';
import {APP_CONFIG, AppConfig, COUNTRY_DOMAIN} from '../app-config/AppConfig';
import {ExternalMapRouteUrlBuilder} from './external-map-route-url-builder.service';
import {YandexMapRouteBuilder} from './yandex-map-route.builder';
import {GoogleMapRouteBuilder} from './google-map-route.builder';

const initExternalRouteBuilderFactory = (appConfig: AppConfig): ExternalMapRouteUrlBuilder => {
    switch (appConfig.countryDomain) {
        case COUNTRY_DOMAIN.RU:
            return new YandexMapRouteBuilder();
        case COUNTRY_DOMAIN.CA:
        case COUNTRY_DOMAIN.UK:
        case COUNTRY_DOMAIN.US:
        case COUNTRY_DOMAIN.IL:
            return new GoogleMapRouteBuilder();
    }
};

export function provideExternalMapRouteUrlBuilder() {
    return makeEnvironmentProviders([
        {
            provide: ExternalMapRouteUrlBuilder,
            useFactory: initExternalRouteBuilderFactory,
            deps: [APP_CONFIG],
        },
    ]);
}
