import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MobileMenuState} from '../models/mobile-menu.state';
import {Data} from '@angular/router';
import {User} from '../../../infrastructure/auth/models/user';
import {MobileMenuItem} from '../misc/mobileMenuItem';
import {MobileNavItemsService} from './mobile-nav-items.service';

const UNINITIALIZED_STATE: MobileMenuState = {
    openedMenu: null,
    activatedSlidePanel: null,
    isVisible: true,
    items: [],
    user: undefined,
};

@Injectable()
export class MobileNavStateService {
    private readonly stateSubject$ = new BehaviorSubject<MobileMenuState>(UNINITIALIZED_STATE);
    private readonly itemsService = inject(MobileNavItemsService);

    public get state$(): Observable<MobileMenuState> {
        return this.stateSubject$.asObservable();
    }

    public handleRouteChange(routeData: Data): void {
        const state = this.stateSubject$.value;
        this.stateSubject$.next({
            ...state,
            isVisible: !routeData.hideMobileNav,
            openedMenu: routeData?.mobileMenuItem,
            activatedSlidePanel: null,
        });
    }

    public handleAuthStateChange(user: User): void {
        const state = this.stateSubject$.value;
        this.stateSubject$.next({
            ...state,
            items: this.itemsService.getMenuItemsForUser(user),
            user: user,
        });
    }

    public handleSladePanelToggle(slidePanel: MobileMenuItem): void {
        const state = this.stateSubject$.value;
        this.stateSubject$.next({
            ...state,
            activatedSlidePanel: state.activatedSlidePanel === slidePanel ? null : slidePanel,
        });
    }
}
