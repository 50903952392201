<button class="menu__button"
    type="button"
    [ngClass]="{'menu__button_active': isActive, 'menu__button_inactive': !isActive}"
    (click)="onClick()">
    @if (loggedIn) {

        <span class="menu__media">
            @if (!isActive) {
                <img class="menu__img"

                    @navIcon
                    [src]="MenuIcons.Search"
                    [alt]="'mobile_nav|search' | localization"/>
            }
            @if (isActive) {
                <img class="menu__img"

                    @navActiveIcon
                    [src]="MenuIcons.SearchActive"
                    [alt]="'mobile_nav|search' | localization"/>
            }
        </span>
        <span class="menu__text">
            {{ "mobile_nav|search" | localization }}
        </span>

    }
    @if (!loggedIn) {

        <span class="menu__media">
            @if (!isActive) {
                <img class="menu__img"

                    @navResumesIcon
                    [src]="MenuIcons.Providers"
                    [alt]="'mobile_nav|vacancies' | localization"/>
            }
            @if (isActive) {
                <img class="menu__img"

                    @navActiveResumesIcon
                    [src]="MenuIcons.ProvidersActive"
                    [alt]="'mobile_nav|resumes' | localization"/>
            }
        </span>
        <span class="menu__text">
            {{ "mobile_nav|resumes" | localization }}
        </span>

    }
</button>
