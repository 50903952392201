import {APP_INITIALIZER, makeEnvironmentProviders} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {APP_CONFIG, AppConfig} from '../app-config/AppConfig';

function initFactory(
    document: Document,
    appConfig: AppConfig,
) {
    return () => {
        const googleGlobalSiteTagId = appConfig.googleGlobalSiteTagId;
        const googleTagManagerId = appConfig.googleTagManagerId;
        if (!(googleGlobalSiteTagId?.length > 0 && googleTagManagerId?.length > 0)) {
            console.log('Failed to initialize google analytics: no config provided');
            return;
        }

        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleGlobalSiteTagId}`;
        script.async = true;
        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.text = 'window.dataLayer = window.dataLayer || [];\n' +
            '                function gtag() { dataLayer.push(arguments);}\n' +
            '                gtag(\'js\', new Date());\n' +
            `                window.googleGlobalSiteTagId = '${googleGlobalSiteTagId}'`;
        document.head.appendChild(script2);

        const script3 = document.createElement('script');
        script3.text = '(function (w, d, s, l, i) {\n' +
            '                    w[l] = w[l] || [];\n' +
            '                    w[l].push({\n' +
            '                        \'gtm.start\':\n' +
            '                            new Date().getTime(), event: \'gtm.js\'\n' +
            '                    });\n' +
            '                    var f = d.getElementsByTagName(s)[0],\n' +
            '                        j = d.createElement(s), dl = l != \'dataLayer\' ? \'&l=\' + l : \'\';\n' +
            '                    j.async = true;\n' +
            '                    j.src =\n' +
            '                        \'https://www.googletagmanager.com/gtm.js?id=\' + i + dl;\n' +
            '                    f.parentNode.insertBefore(j, f);\n' +
            `                })(window, document, 'script', 'dataLayer', '${googleTagManagerId}');`;
        document.head.appendChild(script3);

        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`;
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        noscript.appendChild(iframe);
    };
}

export function provideGoogleTagManagerScripts() {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: initFactory,
            deps: [
                DOCUMENT,
                APP_CONFIG,
            ],
            multi: true,
        },
    ]);
}
