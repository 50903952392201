<button class="menu__button"
    type="button"
    [routerLink]="link"
    routerLinkActive="menu__button_active"
    [routerLinkActiveOptions]="{exact: true}">
    <span class="menu__media">
        @if (!isActive) {
            <img class="menu__img"

                @navIcon
                [src]="icon"
                [alt]="title"/>
        }
        @if (isActive) {
            <img class="menu__img"

                @navActiveIcon
                [src]="highlightedIcon"
                [alt]="title"/>
        }
        <ng-content/>
    </span>
    <span class="menu__text">
        {{ title }}
    </span>
</button>
