import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {WorkType} from '../../../../../../core/catalog/core/api/monolith-api-client';
import {CatalogType} from '../../../../../../pages/catalog/routing/CatalogType';
import {CATALOG_ROUTES} from '../../../../../../pages/catalog/routing/CATALOG_ROUTES';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {CatalogPipesModule} from '../../../../../../core/catalog/pipes/module';
import {AsyncPipe, DecimalPipe} from '@angular/common';
import {LocalizationPipe} from '../../../../../../infrastructure/localization/localization.pipe';

@Component({
    selector: 'nn-vacancy-search-navigation-panel',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        RouterLink,
        CatalogPipesModule,
        AsyncPipe,
        RouterLinkActive,
        LocalizationPipe,
        DecimalPipe,
    ],
})
export class VacancySearchNavigationPanelComponent {
    protected readonly VacancyRoutes = inject(CATALOG_ROUTES)[CatalogType.Vacancy];
    protected readonly WorkType = WorkType;
}
