import {LOCALE_ID, makeEnvironmentProviders} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../app-config/AppConfig';
import {LanguageService} from './language.service';
import {AppLocales} from './AppLocales';

export function provideLocale() {
    return makeEnvironmentProviders([
        {
            provide: LOCALE_ID,
            useFactory: (appConfig: AppConfig, languageService: LanguageService) =>
                AppLocales[appConfig.countryDomain][languageService.currentLanguage],
            deps: [
                APP_CONFIG,
                LanguageService,
            ],
        },
    ]);
}
