@if (alertsService.successMessage$ | async; as message) {
    <div class="notify notify_header">
        <div class="notify__center center">
            @if (message.indexOf('|') > 0) {
                <div>{{ message | localization }}</div>
            }
            @if (message.indexOf('|') <= 0) {
                <div>{{ message }}</div>
            }
            <button class="notify__close"
                (click)="clearMessage()">
                <mat-icon class="mat-icon_close"
                    svgIcon="sprite:icon-close"/>
            </button>
        </div>
    </div>
}
