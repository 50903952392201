import {Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {ActivationEnd, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {FcmService} from './infrastructure/fcm/fcm.service';
import {ChatService} from './core/chat/core/messaging/chat.service';
import {MatDialog} from '@angular/material/dialog';
import {GoogleAnalyticsService} from './infrastructure/google-analytics/google-analytics.service';
import {UtmSubscriptionService} from './pages/catalog/services/utm-subscription.service';
import {AlertsService} from './infrastructure/alerts/alerts.service';
import {NotificationsService} from './core/notifications/services/notifications-service';
import {WithoutResumesNotificationService} from './core/catalog/core/without-resumes-notification.service';
import {ConnectionStatusService} from './infrastructure/connection-status/connection-status.service';
import {tap} from 'rxjs/operators';
import {PreviousRouteService} from './infrastructure/routing/previous-route.service';
import {AsyncPipe, isPlatformBrowser} from '@angular/common';
import {ServiceWorkerCheckForUpdatesService} from './infrastructure/service-worker/service-worker-check-for-updates.service';
import {ServiceWorkerForceUpdateService} from './infrastructure/service-worker/service-worker-force-update.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {AndroidBannerService} from './infrastructure/android-banner/android-banner.service';
import {Observable} from 'rxjs';
import {FooterService} from './infrastructure/footer/footer.service';
import {InterviewBannerService} from './shared/components/interview-banner/interview-banner.service';
import {APP_CONFIG, AppConfig} from './infrastructure/app-config/AppConfig';
import {UserLockStatusService} from './core/users/core/user-lock-status.service';
import {TextDirectionService} from './infrastructure/text-direction/text-direction.service';
import {PageMetaMonitor} from './infrastructure/page-meta/page-meta.monitor';
import {TitleChatUnreadMessagesMonitor} from './infrastructure/page-meta/title-chat-unread-messages.monitor';
import {UserRatingService} from './core/users/core/user-rating/user-rating.service';
import {FreePremiumNotificationService} from './core/users/free-premium-notification.service';
import {GAChatSubscriptions} from './infrastructure/google-analytics/ChatEvents';
import {ClientLoggedInHistoryService} from './core/catalog/core/client-logged-in-history.service';
import {CompletedFeaturesEventConsumer} from './core/catalog/core/completed-features.event-consumer';
import {ScreenModeDirective} from './infrastructure/screen-mode/screen-mode.directive';
import {OfflineMessageComponent} from './layout/offline-message/component';
import {ErrorMessageComponent} from './layout/error-message/error-message.component';
import {SuccessMessageComponent} from './layout/success-message/component';
import {MainFooterComponent} from './layout/footer/component';
import {HeaderComponent} from './layout/header/header/component';
import {MobileNavComponent} from './layout/mobile-nav/components/nav/component';
import {ScreenSizeDetectorComponent} from './infrastructure/responsive-screen-size-detector/screen-size-detector.component';
import {BrowserOnlyDirective} from './shared/directives/platform-only/browser-only.directive';
import {AuthRedirectSubscriptions} from './core/users/core/auth-redirect-subscriptions.service';
import {CatalogViewEventHandlers} from './core/catalog/core/catalog-view-event-handlers';
import {GAPaymentsSubscriptions} from './infrastructure/google-analytics/PaymentsEvents';

@Component({
    selector: 'nn-nanny-app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MobileNavComponent,
        MainFooterComponent,
        RouterOutlet,
        AsyncPipe,
        ScreenModeDirective,
        OfflineMessageComponent,
        ErrorMessageComponent,
        SuccessMessageComponent,
        HeaderComponent,
        ScreenSizeDetectorComponent,
        BrowserOnlyDirective,
    ],
})
export class AppComponent implements OnInit {
    public showFooter$: Observable<boolean>;

    constructor(
        private readonly pageMetaMonitor: PageMetaMonitor,
        private readonly titleChatUnreadMessagesMonitor: TitleChatUnreadMessagesMonitor,
        private router: Router,
        private alertsService: AlertsService,
        private fcmService: FcmService,
        private chatService: ChatService,
        private notificationsService: NotificationsService,
        private mdDialog: MatDialog,
        private readonly bottomSheet: MatBottomSheet,
        private analyticsService: GoogleAnalyticsService,
        private withoutResumesNotificationService: WithoutResumesNotificationService,
        private utmSubscriptionService: UtmSubscriptionService,
        private connectionStatusService: ConnectionStatusService,
        private previousRouteService: PreviousRouteService,
        private swCheckUpdatesService: ServiceWorkerCheckForUpdatesService,
        private swForceUpdateService: ServiceWorkerForceUpdateService,
        private androidBannerService: AndroidBannerService,
        private readonly interviewBannerService: InterviewBannerService,
        private readonly userLockStatusService: UserLockStatusService,
        private readonly textDirectionService: TextDirectionService,
        private readonly userRatingService: UserRatingService,
        private readonly freePremiumNotificationService: FreePremiumNotificationService,
        private readonly gaChatSubscriptions: GAChatSubscriptions,
        private readonly gaPaymentsSubscriptions: GAPaymentsSubscriptions,
        private readonly authRedirectSubscriptions: AuthRedirectSubscriptions,
        private readonly loggedInHistoryService: ClientLoggedInHistoryService,
        private readonly completedFeaturesEventConsumer: CompletedFeaturesEventConsumer,
        private readonly catalogViewEventHandlers: CatalogViewEventHandlers,
        @Inject(PLATFORM_ID) private readonly platformId: object,
        @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
        footerService: FooterService) {
        this.showFooter$ = footerService.footerState$;
    }

    public ngOnInit() {
        this.pageMetaMonitor.run();
        this.titleChatUnreadMessagesMonitor.run();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.mdDialog.closeAll();
                this.bottomSheet.dismiss();
                this.alertsService.clearErrors();
                if (isPlatformBrowser(this.platformId)) {
                    this.analyticsService.pageView();
                }
            }
            if (event instanceof ActivationEnd) {
                if (isPlatformBrowser(this.platformId)) {
                    if (event.snapshot.data['useUtmSubscription']) {
                        this.utmSubscriptionService.subscribe();
                    } else {
                        this.utmSubscriptionService.unsubscribe();
                    }
                }
            }
        });
        this.mdDialog.afterAllClosed
            .pipe(tap(() => this.alertsService.clearErrors()))
            .subscribe();

        if (isPlatformBrowser(this.platformId)) {
            this.swCheckUpdatesService?.init();
            this.swForceUpdateService?.init();
            this.previousRouteService.init();
            this.chatService.init();
            this.notificationsService.init();
            this.fcmService.init();
            this.withoutResumesNotificationService.init();
            this.connectionStatusService.init();
            if (this.appConfig.isAndroidBannerServiceEnabled()) {
                this.androidBannerService.init();
            }
            if (this.appConfig.isInterviewBannerServiceEnabled()) {
                this.interviewBannerService.init();
            }
            this.userLockStatusService.init();
            this.userRatingService.startWaitForRatingDialog();
            this.freePremiumNotificationService.maybeNotify();
            this.gaChatSubscriptions.subscribe();
            this.gaPaymentsSubscriptions.subscribe();
            this.authRedirectSubscriptions.subscribe();
            this.loggedInHistoryService.startWaitingForLoggedInEvents();
            this.completedFeaturesEventConsumer.subscribe();
            this.catalogViewEventHandlers.subscribe();
        }
        this.textDirectionService.init();
    }
}
