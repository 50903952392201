import {Pipe, PipeTransform} from '@angular/core';
import {MobileMenuItem} from '../misc/mobileMenuItem';
import {MobileMenuState} from '../models/mobile-menu.state';

@Pipe({
    name: 'nnActiveMobileMenu',
    pure: true,
    standalone: true,
})
export class NnActiveMobileMenuPipe implements PipeTransform {
    public transform(state: MobileMenuState, item: MobileMenuItem): boolean {
        return state.activatedSlidePanel ? state.activatedSlidePanel === item : state.openedMenu === item;
    }
}
