import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {JWT_OPTIONS} from './jwtoptions.token';
import {JwtOptions} from '../../../app/infrastructure/auth/jwtOptions';

@Injectable({
    providedIn: 'root',
})
export class JwtService {
    private readonly tokenGetter: () => Observable<string>;
    private readonly headerName = 'Authorization';
    private readonly authScheme = 'Bearer ';
    private whitelistedDomains: Array<string | RegExp>;

    constructor(
        @Inject(JWT_OPTIONS) config: JwtOptions,
    ) {
        this.tokenGetter = config.tokenGetter;
        this.whitelistedDomains = config.whitelistedDomains || [];
    }

    private isWhitelistedDomain(request: HttpRequest<unknown>): boolean {
        try {
            const requestUrl = new URL(request.url);
            return (
                this.whitelistedDomains.findIndex(
                    domain =>
                        typeof domain === 'string'
                            ? domain === requestUrl.host
                            : domain instanceof RegExp ? domain.test(requestUrl.host) : false,
                ) > -1
            );
        } catch (err) {
            // if we're here, the request is made
            // to the same domain as the Angular app
            // so it's safe to proceed
            return true;
        }
    }

    private addAuthHeaderInternal(token: string, request: HttpRequest<unknown>): HttpRequest<unknown> {
        if (token && this.isWhitelistedDomain(request)) {
            request = request.clone({
                setHeaders: {
                    [this.headerName]: `${this.authScheme}${token}`,
                },
            });
        }
        return request;
    }

    public addAuthHeader(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
        const token$ = this.tokenGetter();
        return token$.pipe(
            map((token) => this.addAuthHeaderInternal(token, request)),
        );
    }
}
