import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {StorageService} from './storage';

@Injectable()
export class BrowserStorageService implements StorageService {

    constructor(private cookieService: CookieService) { }

    public setItem(key: string, data: unknown): void {
        this.cookieService.put(key, JSON.stringify(data), {
            expires: new Date(2050, 1), secure: false,//cfg.storageCookieUseSecure
        });
    }

    public removeItem(key: string): void {
        this.cookieService.remove(key);
    }

    public getItem(key: string): string | null {
        const value: string = this.cookieService.get(key);
        return !!value ? value : null;
    }
}
