import {GoogleAnalyticsEvent} from './GoogleAnalyticsEvent';
import {AppEventsService} from '../events/app-events.service';
import {GoogleAnalyticsService} from './google-analytics.service';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {
    ChatAppEvents,
    ChatAudioMessagePremiumRestrictionAppliedAppEvent,
    ChatAudioMessageSentAppEvent,
    ChatPremiumRestrictionWhenRespondingToVacancyApplication,
} from '../../core/chat/core/chat-app-events';
import {IdentityService} from '../../core/users/core/identity.service';
import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

const ChatAudioMessageSentEvent = (senderId: string, receiverId: string): GoogleAnalyticsEvent => {
    return {
        name: 'ChatAudioMessageSentEvent',
        parameters: {
            senderId: senderId,
            receiverId: receiverId,
        },
    };
};
const ChatAudioMessagePremiumRestrictionAppliedEvent = (senderId: string, receiverId: string): GoogleAnalyticsEvent => {
    return {
        name: 'ChatAudioMessagePremiumRestrictionAppliedEvent',
        parameters: {
            senderId: senderId,
            receiverId: receiverId,
        },
    };
};

const ChatPremiumRestrictionWhenRespondingToVacancyApplicationEvent = (employerId: string): GoogleAnalyticsEvent => {
    return {
        name: 'ChatPremiumRestrictionWhenRespondingToVacancyApplicationEvent',
        parameters: {
            employerId: employerId,
        },
    };
};

@Injectable({providedIn: 'root'})
export class GAChatSubscriptions implements OnDestroy {
    private readonly destroySubject$ = new Subject<void>();

    constructor(private readonly appEvents: AppEventsService,
                private readonly googleAnalyticsService: GoogleAnalyticsService,
                private readonly identityService: IdentityService) {}

    public subscribe(): void {
        this.appEvents.events$.pipe(
            filter(event => event.eventName === ChatAppEvents.ChatAudioMessageSent),
            tap(e => {
                const event = e as ChatAudioMessageSentAppEvent;
                this.googleAnalyticsService.publishEvent(
                    ChatAudioMessageSentEvent(this.identityService.currentState.user?.Id, event.receiverId),
                );
            }),
            takeUntil(this.destroySubject$),
        ).subscribe();

        this.appEvents.events$.pipe(
            filter(event => event.eventName === ChatAppEvents.ChatAudioMessagePremiumRestrictionApplied),
            tap(e => {
                const event = e as ChatAudioMessagePremiumRestrictionAppliedAppEvent;
                this.googleAnalyticsService.publishEvent(
                    ChatAudioMessagePremiumRestrictionAppliedEvent(this.identityService.currentState.user?.Id, event.receiverId),
                );
            }),
            takeUntil(this.destroySubject$),
        ).subscribe();

        this.appEvents.events$.pipe(
            filter(event => event.eventName === ChatAppEvents.ChatPremiumRestrictionWhenRespondingToVacancyApplication),
            tap(e => {
                const event = e as ChatPremiumRestrictionWhenRespondingToVacancyApplication;
                this.googleAnalyticsService.publishEvent(
                    ChatPremiumRestrictionWhenRespondingToVacancyApplicationEvent(this.identityService.currentState.user?.Id),
                );
            }),
            takeUntil(this.destroySubject$),
        ).subscribe();
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }
}
