import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {LocalizationPipe} from '../../../../../../infrastructure/localization/localization.pipe';
import {NgClass} from '@angular/common';
import {MobileMenuItem} from '../../../../misc/mobileMenuItem';
import {MenuIcons} from '../menu-icons';
import {MOB_NAV_ACTIVE_ICON_ANIMATION, MOB_NAV_ICON_ANIMATION} from '../../../../animations/mob-nav-icon.animation';
import {
    MOB_NAV_ACTIVE_RESUMES_ICON_ANIMATION,
    MOB_NAV_RESUMES_ICON_ANIMATION,
} from '../../../../animations/mob-nav-resumes-icon.animation';
import {MobileNavToggleService} from '../../../../services/mobile-nav-toggle.service';

@Component({
    selector: 'nn-resume-search-menu-item',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    animations: [
        MOB_NAV_ICON_ANIMATION,
        MOB_NAV_ACTIVE_ICON_ANIMATION,
        MOB_NAV_RESUMES_ICON_ANIMATION,
        MOB_NAV_ACTIVE_RESUMES_ICON_ANIMATION,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LocalizationPipe,
        NgClass,
    ],
})
export class ResumeSearchMenuItemComponent {
    @Input({required: true}) loggedIn: boolean;
    @Input({required: true}) isActive: boolean;

    private readonly navigationService = inject(MobileNavToggleService);

    protected onClick(): void {
        this.navigationService.toggleSlidePanel(MobileMenuItem.ResumeSearch);
    }

    protected readonly MenuIcons = MenuIcons;
}
