import {PremiumBannerKind} from '../../pages/common/banners/premium-banner/premium-banner-kind';
import {UserRoleType} from '../../core/catalog/core/api/monolith-api-client';
import {GoogleAnalyticsEvent} from './GoogleAnalyticsEvent';
import {DestroyRef, inject, Injectable, PLATFORM_ID} from '@angular/core';
import {AppEventsService} from '../events/app-events.service';
import {GoogleAnalyticsService} from './google-analytics.service';
import {IdentityService} from '../../core/users/core/identity.service';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {isPaymentSucceededEvent} from '../../core/payments/events/isPaymentSucceededEvent';
import {isPlatformServer} from '@angular/common';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

const BoughtPremiumEvent = (params: {
    banner: PremiumBannerKind,
    userRole: UserRoleType,
}): GoogleAnalyticsEvent => ({
    name: 'BoughtPremiumEvent',
    parameters: params,
});

@Injectable({providedIn: 'root'})
export class GAPaymentsSubscriptions {
    private readonly appEvents = inject(AppEventsService);
    private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
    private readonly identityService = inject(IdentityService);
    private readonly destroyRef = inject(DestroyRef);
    private readonly platformId = inject(PLATFORM_ID);

    public subscribe(): void {
        if (isPlatformServer(this.platformId)) return;

        const user$ = this.identityService.stateChanges.pipe(map(authState => authState.user));
        this.appEvents.events$.pipe(
            filter(event => isPaymentSucceededEvent(event)),
            filter(event => event.result.successful),
            withLatestFrom(user$),
            tap(([appEvent, user]) => {
                const gaEvent = BoughtPremiumEvent({
                    banner: appEvent.buyData.bannerKind,
                    userRole: user && user.isWorker ? UserRoleType.Worker : UserRoleType.Employer,
                });
                this.googleAnalyticsService.publishEvent(gaEvent);
            }),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe();
    }
}
