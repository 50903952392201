@if (identityService.stateChanges | async; as authState) {

    @if (authState.user; as user) {

        @if (!user.isLocked) {

            @if (user?.isWorker) {
                <button class="auth__add button-icon button-icon_add"

                    [nnAnalyticsEvent]="'HeaderCreateResumeButtonClick'"
                    matRipple
                    routerLink="/resume-management">
                    <div class="button-icon__media">
                        <mat-icon class="button-icon__icon button-icon__icon_plus mat-icon_plus"
                            svgIcon="sprite:icon-plus"/>
                    </div>
                    <div class="button-icon__content">{{ 'home_page|resume' | localization }}</div>
                </button>
            }
            @if (user?.isEmployer) {
                <button class="auth__add button-icon button-icon_add"

                    [nnAnalyticsEvent]="'HeaderCreateVacancyButtonClick'"
                    matRipple
                    routerLink="/register/employer-success">
                    <div class="button-icon__media">
                        <mat-icon class="button-icon__icon button-icon__icon_plus mat-icon_plus"
                            svgIcon="sprite:icon-plus"/>
                    </div>
                    <div class="button-icon__content">{{ 'home_page|vacancy' | localization }}</div>
                </button>
            }
            <nn-favorites-header class="auth__favorites"/>
            <nn-chat-header class="auth__chat"/>
            <nn-notifications-header class="auth__notify"/>

        }
        <a class="auth__user-link"
            (click)="onUserClick(user)">
            <span class="auth__user-content">
                <span class="auth__user-text">{{ user?.name }}</span>
                <span class="auth__user-subtext">{{ "home_page|personal_area" | localization }}</span>
            </span>
            <span class="auth__user-ava">
                <nn-avatar [avatarId]="user.AvatarId"
                    [customerId]="user.Id"
                    [customerName]="user.name"
                    [customerLastName]="user.lastName"/>
            </span>
        </a>

    } @else {

        <button class="auth__login"
            [routerLink]="['/login']"
            color="accent"
            mat-button>
            <span class="m-hide">{{ "home_page|signin" | localization }}</span>
            <span class="m-show">
                <mat-icon class="mat-icon_login"
                    svgIcon="sprite:icon-login"/>
            </span>
        </button>

    }

}
