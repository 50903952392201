@if (activatedPanel) {
    <div class="nav__inner"
        @navSlideUpDown>
        <div class="nav__top">
            <div class="nav__content"
                [ngClass]="{'nav__content_resume': activatedPanel === Menu.ResumeSearch, 'nav__content_vacancy': activatedPanel  === Menu.VacancySearch}">
                @switch (activatedPanel) {
                    @case (Menu.ResumeSearch) {
                        <nn-resume-search-navigation-panel class="nav__category"/>
                    }
                    @case (Menu.VacancySearch) {
                        <nn-vacancy-search-navigation-panel class="nav__category nav__category_vacancy"/>
                    }
                    @case (Menu.Account) {
                        <nn-profile-navigation-panel class="nav__profile"/>
                    }
                }
            </div>
        </div>
    </div>
}
