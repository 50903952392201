import {Directive, Inject, Input, PLATFORM_ID, TemplateRef, ViewContainerRef} from '@angular/core';
import {PlatformOnlyDirective} from './platform-only.directive';

@Directive({
    selector: '[nnBrowserOnly]',
    standalone: true,
})
export class BrowserOnlyDirective extends PlatformOnlyDirective {
    @Input('nnBrowserOnly') set conditionInput(value: boolean | '') {
        this.setCondition(value);
    }

    constructor(
        templateRef: TemplateRef<unknown>,
        viewContainer: ViewContainerRef,
        @Inject(PLATFORM_ID) platformId: object) {
        super(templateRef, viewContainer, 'browser', platformId);
    }
}
