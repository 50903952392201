import {Component, inject} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {UserInfoComponent} from '../../../../../../shared/components/user-info/component';
import {NotificationsHeaderComponent} from '../../../../../../pages/common/notifications-header/component';
import {WorkerInterestingComponent} from '../../../../../../pages/common/worker-interesting/component';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {MatIcon} from '@angular/material/icon';
import {LocalizationPipe} from '../../../../../../infrastructure/localization/localization.pipe';
import {TotalUnreadGuestsCountPipe} from '../../../../../../shared/pipes/total-unread-guests-count.pipe';
import {TotalUnreadVacancyApplicationsCountPipe} from '../../../../../../shared/pipes/total-unread-vacancy-applications-count.pipe';
import {ProfileNavigationService} from './profile-navigation.service';
import {UserRoleOnlyDirective} from '../../../../../../core/users/directives/user-role-only.directive';
import {CountryDomainDirective} from '../../../../../../infrastructure/country-domain/country-domain.directive';

@Component({
    selector: 'nn-profile-navigation-panel',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        AsyncPipe,
        UserInfoComponent,
        NotificationsHeaderComponent,
        WorkerInterestingComponent,
        RouterLink,
        RouterLinkActive,
        MatIcon,
        LocalizationPipe,
        TotalUnreadGuestsCountPipe,
        TotalUnreadVacancyApplicationsCountPipe,
        UserRoleOnlyDirective,
        CountryDomainDirective,
    ],
    providers: [ProfileNavigationService],
})
export class ProfileNavigationPanelComponent {
    private readonly navService = inject(ProfileNavigationService);

    public readonly context$ = this.navService.context$;

    public logout(): void {
        this.navService.logout();
    }
}
