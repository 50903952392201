import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ChatService} from '../../core/chat/core/messaging/chat.service';
import {UNREAD_MESSAGES_FORMATTER, UnreadMessagesFormatter} from '../../core/chat/formatters/unread-messages-formatter';
import {PageMetaManager} from './page-meta.manager';
import {isPlatformServer} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class TitleChatUnreadMessagesMonitor {
    constructor(
        private readonly chatService: ChatService,
        private readonly pageMetaManager: PageMetaManager,
        @Inject(UNREAD_MESSAGES_FORMATTER) private readonly unreadMessagesFormatter: UnreadMessagesFormatter,
        @Inject(PLATFORM_ID) private readonly platformId: object) {}

    public run(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        this.chatService.unreadMessagesCount$.subscribe(count => {
            if (count > 0) {
                this.pageMetaManager.setTitle('* ' + this.unreadMessagesFormatter.format(count), false);
            } else {
                this.pageMetaManager.setTitle(null, false);
            }
        });
    }
}
