import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ValueLanguage} from '../../../infrastructure/localization/localization-api/localization-api-client';
import {LanguageService} from '../../../infrastructure/localization/language.service';
import {MatOption, MatSelect} from '@angular/material/select';

@Component({
    selector: 'nn-language-selector',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatSelect,
        MatOption,
    ],
})
export class LanguageSelectorComponent {
    public readonly supportedLanguages = this.languageService.supportedLanguages;

    constructor(private readonly languageService: LanguageService) { }

    public get currentLanguage(): ValueLanguage { return this.languageService.currentLanguage; }

    public onChange(language: ValueLanguage): void {
        this.languageService.selectLanguage(language);
    }
}
