import {inject, Injectable} from '@angular/core';
import {IdentityService} from '../../../../../../core/users/core/identity.service';
import {Observable, switchMap} from 'rxjs';
import {ProfileNavigationContext} from './profile-navigation-context.model';
import {filter, map} from 'rxjs/operators';
import {APP_CONFIG} from '../../../../../../infrastructure/app-config/AppConfig';
import {LoginService} from '../../../../../../core/users/core/session/login.service';
import {Router} from '@angular/router';

@Injectable()
export class ProfileNavigationService {
    private readonly loginService = inject(LoginService);
    private readonly router = inject(Router);
    private readonly identityService = inject(IdentityService);
    private readonly isReferralsEnabled = inject(APP_CONFIG).isReferralsEnabled();

    public get context$(): Observable<ProfileNavigationContext> {
        return this.identityService.stateChanges.pipe(
            filter(authState => !!authState.user),
            map(authState => ({user: authState.user, isReferralsEnabled: this.isReferralsEnabled})),
        );
    }

    public logout(): void {
        this.loginService.logout().pipe(switchMap(() => this.router.navigate(['/login']))).subscribe();
    }
}
