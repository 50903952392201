<a class="chat-header"
    [routerLink]="['/chat']"
    routerLinkActive="chat-header_active">
    <div class="chat-header__media">
        <div class="chat-header__circle"
            mat-ripple>
            <mat-icon class="chat-header__icon mat-icon_bubble"
                svgIcon="sprite:icon-bubble"/>
        </div>
        @if (messageCount$ | async; as msgCount) {
            <span class="chat-header__count">{{ msgCount }}</span>
        }
    </div>
    <div class="chat-header__text">{{ 'chat_header|title' | localization }}</div>
</a>
