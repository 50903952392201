@if (state$ | async; as state) {
    @if (state.isVisible) {
        <div class="nav">
            <nn-mobile-nav-slide-panel [activatedPanel]="state.activatedSlidePanel"/>
            <nn-menu-panel class="nav__bottom"
                [state]="state"
                [user]="state.user"
                [items]="state.items"/>
        </div>
    }
}
