@if (supportedLanguages.length > 1) {
    <mat-select class="lang-selector"

        (selectionChange)="onChange($event.value)"
        [value]="currentLanguage">
        @for (language of supportedLanguages; track language) {
            <mat-option [value]="language">{{ language }}
            </mat-option>
        }
    </mat-select>
}
