import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration, withNoHttpTransferCache} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideLocalization} from './infrastructure/localization/module';
import {CookieModule} from 'ngx-cookie';
import {provideGeolocationServices} from './core/geo/core/services/module';
import {provideMarkdown} from 'ngx-markdown';
import {HttpClient, provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {provideBrowserStorage} from '../modules/storage/browser.providers';
import {provideApiBaseUrls} from './infrastructure/api/provide-api-base-urls';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {provideLocationManager} from './core/geo/core/services/providers';
import {providePwaInstallService} from './infrastructure/pwa/providers';
import {provideTurnstile} from './infrastructure/turnstile/providers';
import {provideMaterialConfigs} from './infrastructure/material/providers';
import {provideMapStateService} from './pages/catalog/search/map/providers';
import {provideLocale} from './infrastructure/localization/locale-provider';
import {provideDefaultCurrencyCode} from './infrastructure/localization/currency-code-provider';
import {provideErrorHandler} from './infrastructure/error-handler/provider';
import {provideFcmServices} from './infrastructure/fcm/providers';
import {provideExternalMapRouteUrlBuilder} from './infrastructure/external-map-routes/providers';
import {JwtInterceptorFn} from '../modules/angular2-jwt-beta/src/jwt.interceptor';
import {ApiErrorInterceptorFn} from './infrastructure/api/api-error-handling/interceptor';
import {provideMatPaginatorIntl} from './infrastructure/material/provide-mat-paginator.intl';
import {provideMatIconRegistry} from './infrastructure/material/provide-mat-icon.registry';
import {provideIdentityService} from './core/users/core/session/provideIdentityService';
import {SsrAuthHttpInterceptorFn} from './infrastructure/ssr-auth-http-interceptor/ssr-auth-http.interceptor';
import {
    provideCloudflareImageTransformationsLoader,
} from './infrastructure/image-processing/provideCloudflareImageTransformationsLoader';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            routes,
            withEnabledBlockingInitialNavigation(),
            withInMemoryScrolling({anchorScrolling: 'enabled', scrollPositionRestoration: 'disabled'}),
        ),
        provideHttpClient(
            withFetch(),
            withInterceptors([ // WARNING: order of interceptors is important!
                ApiErrorInterceptorFn, // this interceptor should be first
                // RefreshTokenInterceptorFn, // this interceptor should be before JwtInterceptorFn
                JwtInterceptorFn,
                SsrAuthHttpInterceptorFn,
            ]),
        ),
        provideClientHydration(withNoHttpTransferCache()),
        provideAnimationsAsync(),
        provideLocationManager(),
        providePwaInstallService(),
        provideTurnstile(),
        provideFcmServices(),
        provideBrowserStorage(),
        provideApiBaseUrls(),
        provideErrorHandler(),
        provideGeolocationServices(),
        provideLocale(),
        provideLocalization(),
        provideDefaultCurrencyCode(),
        provideMaterialConfigs(),
        provideMatPaginatorIntl(),
        provideMatIconRegistry(),
        provideEnvironmentNgxMask(),
        provideMarkdown({loader: HttpClient}),
        provideMapStateService(), // todo: check if this is appropriate place for this provider
        provideExternalMapRouteUrlBuilder(), // todo: check if this is appropriate place for this provider
        provideIdentityService(),
        provideCloudflareImageTransformationsLoader(),
        importProvidersFrom(
            CookieModule.withOptions(),
        ),
    ],
};
