import {APP_CONFIG, COUNTRY_DOMAIN} from '../../../infrastructure/app-config/AppConfig';
import {RuUnreadMessagesFormatter} from './ru-unread-messages-formatter';
import {EnUnreadMessagesFormatter} from './en-unread-messages-formatter';
import {IlUnreadMessagesFormatter} from './il-unread-messages-formatter';
import {inject, InjectionToken} from '@angular/core';

export interface UnreadMessagesFormatter {
    format(messageCount: number): string;
}

const unreadMessagesFormatterFactory = (): UnreadMessagesFormatter => {
    switch (inject(APP_CONFIG).countryDomain) {
        case COUNTRY_DOMAIN.RU:
            return new RuUnreadMessagesFormatter();
        case COUNTRY_DOMAIN.CA:
        case COUNTRY_DOMAIN.UK:
        case COUNTRY_DOMAIN.US:
            return new EnUnreadMessagesFormatter();
        case COUNTRY_DOMAIN.IL:
            return new IlUnreadMessagesFormatter();
    }
};

export const UNREAD_MESSAGES_FORMATTER = new InjectionToken<UnreadMessagesFormatter>('UnreadMessagesFormatter', {
    providedIn: 'root',
    factory: unreadMessagesFormatterFactory,
});
