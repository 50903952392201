import {makeEnvironmentProviders} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getRussianPaginatorIntl} from './russian-paginator-intl';
import {LocalizationService} from '../localization/localization.service';
import {StringInterpolationService} from '../text-processing/string-interpolation.service';

export function provideMatPaginatorIntl() {
    return makeEnvironmentProviders([
        {
            provide: MatPaginatorIntl,
            useFactory: getRussianPaginatorIntl,
            deps: [
                LocalizationService,
                StringInterpolationService,
            ],
        },
    ]);
}
