import {EnvironmentProviders} from '@angular/core';
import {provideServiceWorker} from '@angular/service-worker';
import {environment} from '../../../environments/environment';

export function provideServiceWorkerCustom(): EnvironmentProviders {
    /*  Static cache-bust value here is for one-time cache busting.
        Incorrect nginx settings led to ngsw-worker.js being stuck in users browsers cache.
        Later this query param can be removed.
     */
    return provideServiceWorker('ngsw-worker.js', {
        enabled: environment.registerServiceWorker,
        registrationStrategy: 'registerWhenStable:30000',
    });
}
