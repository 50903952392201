import {Component} from '@angular/core';
import {LocalizationPipe} from '../../../infrastructure/localization/localization.pipe';

@Component({
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        LocalizationPipe,
    ],
})
export class OfflineOverlayComponent {

}
