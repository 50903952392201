import {Directive, Inject, OnChanges, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

type Platform = 'browser' | 'ssr';

@Directive()
export abstract class PlatformOnlyDirective implements OnInit, OnChanges {
    protected condition: boolean = true;

    protected setCondition(value: boolean | '') {
        this.condition = value === '' || value === true;
    }

    private readonly current: Platform;
    private hasView: boolean = false;

    protected constructor(
        private readonly templateRef: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
        private readonly onlyPlatform: Platform,
        @Inject(PLATFORM_ID) platformId: object) {
        this.current = isPlatformBrowser(platformId) ? 'browser' : 'ssr';
    }

    public ngOnInit(): void {
        this.apply();
    }

    public ngOnChanges(): void {
        this.apply();
    }

    private apply(): void {
        if (this.current !== this.onlyPlatform)
            return;
        if (this.condition && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!this.condition && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
