import {Inject, Injectable, Optional} from '@angular/core';
import {TimeTrackingServiceFactory} from './time-tracking/time-tracking-service.factory';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {IdentityService} from '../identity.service';
import {UsersDialogsFacade} from '../../dialogs/users-dialogs.facade';
import {UserSupportService} from '../user-support.service';
import {switchMapCombine, switchMapIgnore} from '../../../../shared/misc/operators';
import {UserRatingStateService} from './user-rating-state.service';
import {UserRatingDialogResult} from '../../dialogs/user-rating/models/user-rating-dialog-result';
import {LeaveReviewDialogResult} from '../../dialogs/external-review-services/models/leave-review-dialog-result';
import {GuidUtils} from '../../../../infrastructure/helpers/guid.utils';
import {GooglePlayLinkProvider} from '../../../../infrastructure/google-play-link.provider';
import {APP_CONFIG, AppConfig} from '../../../../infrastructure/app-config/AppConfig';
import {WINDOW_TOKEN} from '../../../../infrastructure/dom/WINDOW_TOKEN';

@Injectable({
    providedIn: 'root',
})
export class UserRatingService {

    private readonly msInHour = 1000 * 60 * 60;
    private readonly triggerHours = 5;
    private readonly userIdChangesSubject$ = new Subject<string>();
    private readonly timer = this.timeTrackingServiceFactory.create('rating');
    private refreshSubject$: BehaviorSubject<boolean>;

    constructor(
        private readonly identityService: IdentityService,
        private readonly timeTrackingServiceFactory: TimeTrackingServiceFactory,
        private readonly stateService: UserRatingStateService,
        private readonly usersDialogsFacade: UsersDialogsFacade,
        private readonly userSupportService: UserSupportService,
        private readonly gPlayLinkProvider: GooglePlayLinkProvider,
        @Optional() @Inject(WINDOW_TOKEN) private readonly window: Window,
        @Inject(APP_CONFIG) private readonly appConfig: AppConfig) {
    }

    public startWaitForRatingDialog(): void {

        this.userIdChangesSubject$.pipe(
            filter(userId => !!userId),
            distinctUntilChanged(),
            switchMapCombine(() => this.refreshSubject$),
            tap(([userId, showDialogNow]) => this.waitOrSkip(userId, showDialogNow)),
        ).subscribe();

        this.identityService.stateChanges.pipe(
            map(authState => authState.user?.Id),
            map(userId => GuidUtils.normalize(userId)),
            distinctUntilChanged(),
            tap(() => this.timer.stopTimeTracking()),
            tap(() => {
                this.refreshSubject$?.complete();
                this.refreshSubject$ = new BehaviorSubject<boolean>(false);
            }),
        ).subscribe(this.userIdChangesSubject$);
    }

    private waitOrSkip(userId: string, showDialogNow: boolean): void {

        const state = this.stateService.getState(userId);

        if (!state || !state.rating && !state.ratingSkipped) {
            this.waitForRatingDialog(userId);
        } else if (!state.ratingSkipped && !state.reviewLeaved && !state.reviewSkipped) {
            this.waitForExternalReviewServicesDialog(userId, showDialogNow);
        }
    }

    private get signal$(): Observable<void> {
        return this.timer.elapsedMs$.pipe(
            map(elapsed => elapsed / this.msInHour),
            filter(hours => hours >= this.triggerHours),
            tap(() => this.timer.stopTimeTracking()),
            map(() => null as any),
        );
    }

    private waitForRatingDialog(userId: string): void {

        this.timer.startTimeTracking(userId);

        this.signal$.pipe(
            switchMap(() => this.usersDialogsFacade.openUserRating()),
            switchMap(matBottomSheetRef => matBottomSheetRef.afterDismissed()),
            switchMapIgnore(result => this.handleUserRatingDialogResult(userId, result)),
            filter(result => result?.rating >= 4),
            take(1),
            tap(() => this.refreshSubject$.next(true)),
            takeUntil(this.userIdChangesSubject$),
        ).subscribe();
    }

    private waitForExternalReviewServicesDialog(userId: string, showDialogNow: boolean): void {
        this.signal$.pipe(
            switchMap(() => this.openExternalReviewServices(userId)),
            takeUntil(this.userIdChangesSubject$),
        ).subscribe();

        if (showDialogNow) {
            this.openExternalReviewServices(userId).subscribe();
        } else {
            this.timer.startTimeTracking(userId);
        }
    }

    private handleUserRatingDialogResult(userId: string, result: UserRatingDialogResult): Observable<void> {

        if (!result) {
            this.stateService.patchState(userId, {ratingSkipped: true});
            return of(null);
        }
        if (result.showLater) {
            this.timer.restartTimeTracking(userId);
            return of(null);
        }
        if (result.rating) {
            this.stateService.patchState(userId, {rating: result.rating});
            return this.userSupportService.sendRating(result.rating, result.message);
        }
        return of(null);
    }

    private openExternalReviewServices(userId: string): Observable<void> {

        return this.usersDialogsFacade.openExternalReviewServices().pipe(
            switchMap(matBottomSheetRef => matBottomSheetRef.afterDismissed()),
            tap(result => this.handleExternalReviewServicesDialogResult(userId, result)),
            filter(result => result.isLeaved),
            tap(() => this.goToExternalLink()),
            map(() => null),
        );
    }

    private goToExternalLink(): void {
        const url = this.gPlayLinkProvider.getLink(this.appConfig.countryDomain);
        this.window?.open(url, '_self');
    }

    private handleExternalReviewServicesDialogResult(userId: string, result: LeaveReviewDialogResult): void {

        if (!result) {
            this.stateService.patchState(userId, {reviewSkipped: true});
        } else if (result.isLeaved) {
            this.stateService.patchState(userId, {reviewLeaved: true});
        } else if (result.showLater) {
            this.timer.restartTimeTracking(userId);
        }
    }
}
