import {Injectable, makeEnvironmentProviders} from '@angular/core';
import {from, Observable, Observer, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AudioCompressionService} from './audio-compression.service';

import MPEGMode from 'lamejs/src/js/MPEGMode';
import Lame from 'lamejs/src/js/Lame';
import BitStream from 'lamejs/src/js/BitStream';
import lamejs from 'lamejs';

window['MPEGMode'] = MPEGMode;
window['Lame'] = Lame;
window['BitStream'] = BitStream;

export function provideAudioCompression() {
    return makeEnvironmentProviders([
        {
            provide: AudioCompressionService,
            useClass: AudioCompressionServiceImpl,
        },
    ]);
}

@Injectable()
class AudioCompressionServiceImpl extends AudioCompressionService {
    private static encodeMono(channels, sampleRate, samples): string {
        const buffer = [];
        const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
        let remaining = samples.length;
        const maxSamples = 1152;
        for (let i = 0; remaining >= maxSamples; i += maxSamples) {
            const mono = samples.subarray(i, i + maxSamples);
            const mp3buf = mp3enc.encodeBuffer(mono);
            if (mp3buf.length > 0) {
                buffer.push(new Int8Array(mp3buf));
            }
            remaining -= maxSamples;
        }
        const d = mp3enc.flush();
        if (d.length > 0) {
            buffer.push(new Int8Array(d));
        }

        const blob = new Blob(buffer, {type: 'audio/mp3'});
        return window.URL.createObjectURL(blob);

    }

    private static readAsArrayBuffer(blob: Blob): Observable<ArrayBuffer> {
        return new Observable((observer: Observer<ArrayBuffer>) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onloadend = () => {

                observer.next(reader.result as ArrayBuffer);
                observer.complete();

            };
        });
    }

    private static getBlobFromObjectUrl(objectUrl: string): Observable<Blob> {
        return from(fetch(objectUrl))
            .pipe(switchMap(r => r.blob()));
    }

    public compress(objectUrl: string): Observable<string> {
        if (!objectUrl) return of(null);
        return AudioCompressionServiceImpl.getBlobFromObjectUrl(objectUrl).pipe(
            switchMap(blob => AudioCompressionServiceImpl.readAsArrayBuffer(blob)),
            map((audioData => {
                    const wav = lamejs.WavHeader.readHeader(new DataView(audioData));
                    const samples = new Int16Array(audioData, wav.dataOffset, wav.dataLen / 2);
                    return AudioCompressionServiceImpl.encodeMono(wav.channels, wav.sampleRate, samples);
                }),
            ),
        );
    }
}
