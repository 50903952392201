import {EnvironmentProviders, makeEnvironmentProviders} from '@angular/core';
import {IMAGE_LOADER, ImageLoaderConfig} from '@angular/common';
import {PhotoSize} from '../../shared/services/catalog-photo-url.service';
import {APP_CONFIG, AppConfig} from '../app-config/AppConfig';

const PLACEHOLDER_QUALITY = '20';

export interface PhotoSizeImageLoaderParams {
    photoSize: PhotoSize,
}

function loaderFactory(appConfig: AppConfig): (config: ImageLoaderConfig) => string {
    const zone = appConfig.cloudflareZone;
    return config => {

        let params = `format=auto`;

        const photoPredefinedSize = (config.loaderParams as PhotoSizeImageLoaderParams)?.photoSize;
        if (photoPredefinedSize === 'full')
            params += `,width=450`;
        else if (photoPredefinedSize === 'miniature')
            params += `,width=160`;
        else if (config.width) params += `,width=${config.width}`;

        // When requesting a placeholder image we ask for a low quality image to reduce the load time.
        if (config.isPlaceholder) {
            params += `,quality=${PLACEHOLDER_QUALITY}`;
        }

        return `https://${zone}/cdn-cgi/image/${params}/${config.src}`;
    };
}

export function provideCloudflareImageTransformationsLoader(): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: IMAGE_LOADER,
            useFactory: loaderFactory,
            deps: [APP_CONFIG],
        },
    ]);
}
