import {APP_INITIALIZER, makeEnvironmentProviders} from '@angular/core';
import {ScrollPositionRestorationService} from './scroll-position-restoration.service';

function initScrolling(scrollPositionRestorationService: ScrollPositionRestorationService): () => void {
    return () => scrollPositionRestorationService.init();
}

export function provideScrollPositionRestoration() {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: initScrolling,
            deps: [ScrollPositionRestorationService],
            multi: true,
        },
    ]);
}
