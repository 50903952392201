import {bootstrapApplication} from '@angular/platform-browser';
import {config} from './app/app.config.browser';
import {AppComponent} from './app/app.component';
import {fetchJson} from './config/fetchJson';
import {APP_CONFIG, AppConfig, AppConfigData, COUNTRY_DOMAIN} from './app/infrastructure/app-config/AppConfig';
import {initSentry} from './app/infrastructure/sentry/init-sentry';
import {registerLocaleData} from '@angular/common';
import hasOwn from 'object.hasown';

export function applyObjectHasOwnShim() {
    if (!Object.hasOwn)
        hasOwn.shim();
}

applyObjectHasOwnShim();

async function registerLocales(countryDomain: COUNTRY_DOMAIN): Promise<void> {
    switch (countryDomain) {
        case COUNTRY_DOMAIN.RU:
            registerLocaleData((await import('@angular/common/locales/ru')).default, 'ru');
            break;
        case COUNTRY_DOMAIN.IL:
            // eslint-disable-next-line no-case-declarations
            const heLocales = await Promise.all([
                import('@angular/common/locales/he'),
                import('@angular/common/locales/extra/he'),
            ]);
            registerLocaleData(
                heLocales[0].default,
                'he-IL',
                heLocales[1].default,
            );
            break;
    }
}

fetchJson<AppConfigData>('/assets/config/app-config.json').then(appConfig => {
    const cfg = new AppConfig(appConfig, false);
    initSentry(cfg.releaseName);
    const appConfigProvider = {
        provide: APP_CONFIG,
        useValue: cfg,
    };
    config.providers.unshift(appConfigProvider);
    return registerLocales(cfg.countryDomain);
}).then(() =>
    bootstrapApplication(AppComponent, config),
).catch((err) => console.error(err));
