import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {AvatarComponent} from '../../../../../../pages/common/avatar/component';
import {LocalizationPipe} from '../../../../../../infrastructure/localization/localization.pipe';
import {User} from '../../../../../../infrastructure/auth/models/user';
import {MobileMenuItem} from '../../../../misc/mobileMenuItem';
import {MobileNavToggleService} from '../../../../services/mobile-nav-toggle.service';

@Component({
    selector: 'nn-profile-menu-item',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AvatarComponent,
        LocalizationPipe,
    ],
})
export class ProfileMenuItemComponent {
    @Input({required: true}) user: User;
    @Input({required: true}) isActive: boolean;

    private readonly navigationService = inject(MobileNavToggleService);

    protected onClick(): void {
        this.navigationService.toggleSlidePanel(MobileMenuItem.Account);
    }
}
