import {inject, Injectable} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IdentityService} from '../../../core/users/core/identity.service';
import {MobileNavToggleService} from './mobile-nav-toggle.service';
import {MobileNavStateService} from './mobile-nav-state.service';

@Injectable()
export class MobileNavService {
    private readonly router = inject(Router);
    private readonly stateService = inject(MobileNavStateService);
    private readonly identityService = inject(IdentityService);
    private readonly navToggleService = inject(MobileNavToggleService);

    public init(): void {
        this.router.events.pipe(
            filter(event => event instanceof ActivationEnd),
            filter((event: ActivationEnd) => event.snapshot.firstChild == null),
            tap(event => this.stateService.handleRouteChange(event.snapshot.data)),
            takeUntilDestroyed(),
        ).subscribe();

        this.identityService.stateChanges.pipe(
            map(authState => authState.user),
            tap(user => this.stateService.handleAuthStateChange(user)),
            takeUntilDestroyed(),
        ).subscribe();

        this.navToggleService.toggles$.pipe(
            tap(slidePanel => this.stateService.handleSladePanelToggle(slidePanel)),
            takeUntilDestroyed(),
        ).subscribe();
    }

}
