import {APP_INITIALIZER, makeEnvironmentProviders} from '@angular/core';
import {Observable} from 'rxjs';
import {IdentityService} from '../identity.service';
import {AuthState} from '../../../../infrastructure/auth/models/authState';
import {ServerSessionService} from './server-session.service';
import {ServerSessionRequestOptimizerDecorator} from './server-session-request-optimizer.decorator';

function initIdentityService(identityService: IdentityService): () => Observable<AuthState> {
    return () => identityService.init();
}

export function provideIdentityService() {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: initIdentityService,
            deps: [IdentityService],
            multi: true,
        },
        IdentityService,
        {
            provide: ServerSessionService,
            useFactory: () => new ServerSessionRequestOptimizerDecorator(new ServerSessionService()),
        },
    ]);
}
