import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {GeoPlace} from '../models/GeoPlace';
import {GeolocationService} from '../services/abstract/geolocation.service';
import {inject, Injectable, PLATFORM_ID} from '@angular/core';
import {IPostalCodeGeodataDto} from '../../api/geo-api-client';
import {GeoapifyApiClient} from './api/geoapify-api-client';
import {ReverseGeocodeRequest} from './api/reverse-geocode.request';
import {GeocodeType} from './api/geocode-type';
import {Geodata} from './api/geodata';
import {isPlatformServer} from '@angular/common';
import {Suggestion} from '../services/abstract/geo-autocomplete.service';
import {GeoapifySuggestion} from './suggestion';

@Injectable({
    providedIn: 'root',
})
export class GeoapifyGeolocationService extends GeolocationService {
    private readonly apiClient = inject(GeoapifyApiClient);
    private readonly platformId = inject(PLATFORM_ID);

    constructor() {
        super();
    }

    public getPlaceByCoordinates(latitude: number, longitude: number): Observable<GeoPlace> {
        if (isPlatformServer(this.platformId) || !latitude || !longitude) return of(null);
        const request: ReverseGeocodeRequest = {
            type: GeocodeType.amenity,
            lat: latitude,
            lon: longitude,
            filter: `countrycode:us`,
        };
        return this.apiClient.reverseGeocode(request).pipe(map(r => {
            if (!r.results?.length) return undefined;
            return this.createGeoPlace(r.results[0] as (Geodata & IPostalCodeGeodataDto));
        }));
    }

    public override getPlaceBySuggestion(suggestion: Suggestion): Observable<{ geoPlace: GeoPlace; label: string }> {
        if (isPlatformServer(this.platformId) || !suggestion) return of(null);
        const geodata = (suggestion as GeoapifySuggestion)?.data as (Geodata & IPostalCodeGeodataDto);
        if (!geodata) return of(null);
        const geoPlace = this.createGeoPlace(geodata);
        return of({geoPlace: geoPlace, label: geoPlace.formatted_address});
    }

    public searchPlaceByText(): Observable<GeoPlace> {
        return undefined;
    }

    private createGeoPlace(geodata: (Geodata & IPostalCodeGeodataDto)): GeoPlace {
        return {
            lat: geodata.lat || geodata.latitude,
            lng: geodata.lon || geodata.longitude,
            postal: geodata.postcode || geodata.postalCode,
            country: geodata.country,
            regionShort: geodata.state_code || geodata.state,
            locality: geodata.city || geodata.locality,
            street: geodata.street,
            streetNumber: geodata.housenumber,
            formatted_address: [
                [
                    geodata.address_line1 || geodata.street,
                    geodata.city || geodata.locality,
                ].filter(Boolean).join(', '),
                [
                    geodata.state_code || geodata.state,
                    geodata.postcode || geodata.postalCode,
                ].filter(Boolean).join(' '),
            ].filter(Boolean).join(' '),
        };
    }
}
