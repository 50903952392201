<div class="main">
    <nn-screen-size-detector *nnBrowserOnly/>

    <nn-common-header class="m-hide main__header"
        *nnScreenMode="'DESKTOP';Ssr:true"/>

    <main class="main__content">
        <router-outlet/>
    </main>

    @if (showFooter$ | async) {
        <nn-main-footer class="main__footer"/>
    }

    <nn-mobile-nav class="m-show"
        *nnScreenMode="'MOBILE';Ssr:true"/>

    <nn-layout-offline-message/>
    <nn-layout-error-message/>
    <nn-layout-success-message/>
</div>
