<button class="menu__button"
    type="button"
    [ngClass]="{'menu__button_active': isActive, 'menu__button_inactive': !isActive}"
    (click)="onClick()">
    @if (loggedIn) {

        <span class="menu__media">
            @if (!isActive) {
                <img class="menu__img"

                    @navIcon
                    [src]="MenuIcons.Search"
                    [alt]="'mobile_nav|search' | localization"/>
            }
            @if (isActive) {
                <img class="menu__img"

                    @navActiveIcon
                    [src]="MenuIcons.SearchActive"
                    [alt]="'mobile_nav|search' | localization"/>
            }
        </span>
        <span class="menu__text">
            {{ "mobile_nav|search" | localization }}
        </span>

    }
    @if (!loggedIn) {

        <span class="menu__media">
            @if (!isActive) {
                <img class="menu__img"

                    @navVacanciesIcon
                    [src]="MenuIcons.Jobs"
                    [alt]="'mobile_nav|vacancies' | localization"/>
            }
            @if (isActive) {
                <img class="menu__img"

                    @navActiveVacanciesIcon
                    [src]="MenuIcons.JobsActive"
                    [alt]="'mobile_nav|vacancies' | localization"/>
            }
        </span>
        <span class="menu__text">
            {{ "mobile_nav|vacancies" | localization }}
        </span>

    }
</button>
