import {LocationManager} from './LocationManager';
import {APP_INITIALIZER, makeEnvironmentProviders} from '@angular/core';

function initLocationManagerFactory(locationManager: LocationManager): () => void {
    return () => locationManager.init();
}

export function provideLocationManager() {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: initLocationManagerFactory,
            deps: [LocationManager],
            multi: true,
        },
    ]);
}
